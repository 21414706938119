// Adapted from react-error-boundary: https://github.com/bvaughn/react-error-boundary/blob/master/src/index.tsx#L162-L169
// To supplement @sentry/react/errorboundary: https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/errorboundary.tsx

import { useState } from 'react';

export default function useErrorHandler<P = Error>(
  givenError?: P | undefined,
): React.Dispatch<React.SetStateAction<P | undefined>> {
  const [error, setError] = useState<P | undefined>();
  if (givenError !== undefined) throw givenError;
  if (error !== undefined) throw error;
  return setError;
}
